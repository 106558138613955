var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-row",
        { staticClass: "upload-container" },
        [
          _vm.files && _vm.files.length
            ? _vm._l(_vm.files, function(file, index) {
                return _c("vs-col", { key: index, staticClass: "md:w-1/3" }, [
                  _c("div", { staticClass: "upload-file-content" }, [
                    _c(
                      "button",
                      {
                        staticClass: "remove-file",
                        on: {
                          click: function($event) {
                            return _vm.removeFile(file)
                          }
                        }
                      },
                      [_c("i", { staticClass: "onpoint-x" })]
                    ),
                    _c("div", { staticClass: "upload-file-text-content" }, [
                      _c("p", [_vm._v(_vm._s(file.name))])
                    ])
                  ])
                ])
              })
            : _vm._e(),
          _c("vs-col", { staticClass: "md:w-1/3" }, [
            _c(
              "div",
              {
                staticClass: "drag-drop-file",
                on: {
                  drop: function($event) {
                    return _vm.setValue($event.dataTransfer.files)
                  }
                }
              },
              [
                _c(
                  "label",
                  {
                    staticClass: "drag-drop-file-text-content",
                    attrs: { for: "upload" }
                  },
                  [
                    _c("i", { staticClass: "onpoint-file" }),
                    _c("p", { staticClass: "text" }, [
                      _vm._v("Arraste ou clique aqui")
                    ])
                  ]
                ),
                _c("input", {
                  attrs: {
                    id: "upload",
                    type: "file",
                    name: "avatar",
                    multiple: ""
                  },
                  on: {
                    change: function($event) {
                      return _vm.setValue($event.target.files)
                    }
                  }
                })
              ]
            )
          ])
        ],
        2
      ),
      _c(
        "vs-row",
        [
          _c(
            "vs-col",
            { attrs: { "vs-type": "flex", "vs-justify": "end" } },
            [
              _c(
                "vs-button",
                {
                  attrs: { color: "primary" },
                  nativeOn: {
                    click: function($event) {
                      return _vm.uploadDocument()
                    }
                  }
                },
                [
                  _c("span", { staticClass: "font-semibold pr-2 pl-2" }, [
                    _vm._v("\n          Enviar documentos\n        ")
                  ])
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }