var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.tipoCanalCorretora.Descricao
    ? _c("div", { staticClass: "tag-canal-corretor", class: _vm.view }, [
        _c("p", [
          _c("span", { staticClass: " tag-canal-corretor-prefix" }, [
            _vm._v("Canal: ")
          ]),
          _c("span", { staticClass: " tag-canal-corretor-canal" }, [
            _vm._v(
              _vm._s(_vm.obterApenasOCanal(_vm.tipoCanalCorretora.Descricao))
            )
          ])
        ])
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }